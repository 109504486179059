<template>
    <BlockContainer
        :background-color="data.backgroundColor"
        :bottom-angle="data.bottomAngle"
        :angle-background-color="data.nextBackgroundColor"
        :id="'template-gallery-' + slugify(data.title)"
    >
        <TabContentView
            v-model="currentProduct"
            :tabs="tabs"
            :slug="currentProduct"
            url-param=""
            class="pb-24 pt-14"
            @update:model-value="selectProduct"
        >
            <template #tab-heading>
                <h3 class="mb-8">Product Type</h3>
            </template>
            <template #content>
                <div id="product-container">
                    <div v-for="product in products" :key="product.name">
                        <div v-if="slugify(product.name) === currentProduct">
                            <h2 class="leading-10">
                                {{ product.name }} {{ data.title }}
                            </h2>
                            <WysiwygContent
                                :content="data.description"
                                class="mb-8 mt-6"
                            />
                            <div class="mb-12 lg:flex lg:flex-row">
                                <TemplateMaterialFilter
                                    :products="products"
                                    :current-product="currentProduct"
                                    :material-guide-link="data.materialGuide"
                                    @select-material="selectMaterial"
                                />
                                <TemplateSizeFilter
                                    :products="products"
                                    :current-product="currentProduct"
                                    :current-material="currentMaterial"
                                    @select-size="selectSize"
                                />
                            </div>
                            <div v-if="currentSize != null">
                                <div class="mb-8 lg:flex lg:flex-row">
                                    <div
                                        v-if="currentSize.artworkTemplatePDF"
                                        class="mr-2 flex-1"
                                    >
                                        ARTWORK TEMPLATE
                                        <a
                                            class="mt-4 flex items-center gap-2"
                                            :href="
                                                currentSize.artworkTemplatePDF
                                            "
                                            target="_blank"
                                        >
                                            <Icon name="download-light"></Icon>
                                            Download template guide
                                        </a>
                                    </div>
                                    <div
                                        v-if="product.artworkGuide"
                                        class="ml-2 flex-1"
                                    >
                                        ARTWORK GUIDE
                                        <a
                                            class="mt-4 flex items-center gap-2"
                                            :href="product.artworkGuide"
                                            target="_blank"
                                        >
                                            <Icon name="download-light"></Icon>
                                            Download setup guide
                                        </a>
                                    </div>
                                </div>
                                <div
                                    v-if="currentSize.tolerancePDF"
                                    class="mt-24"
                                >
                                    <p class="mb-2">
                                        Need technical quality tolerance info
                                        for this
                                        {{ product.name.toLowerCase() }}?
                                    </p>
                                    <a
                                        :href="currentSize.tolerancePDF"
                                        target="_blank"
                                    >
                                        Download the
                                        {{ product.name }} Technical Tolerance
                                        Form
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentProduct == ''">
                        <h2 class="leading-10">
                            {{ data.title }}
                        </h2>
                        <WysiwygContent
                            :content="data.description"
                            class="mb-8 mt-6"
                        />
                    </div>
                </div>
            </template>
            <template #cta></template>
        </TabContentView>
    </BlockContainer>
</template>

<script setup lang="ts">
import { reactive, toRefs } from 'vue';
import TemplateGalleryType from '~/types/TemplateGalleryType';
import TemplateGalleryProductType from '~/types/TemplateGalleryProductType';
import TemplateGalleryProductMaterialType from '~/types/TemplateGalleryProductMaterialType';
import TemplateGalleryProductMaterialSizeType from '~/types/TemplateGalleryProductMaterialSizeType';
import TemplateGalleryProductMaterialSizeDimensionType from '~/types/TemplateGalleryProductMaterialSizeDimensionType';
import TabContentViewTabType from '~/types/TabContentViewTabType';
import BlockContainer from '~/components/BlockContainer.vue';
import TabContentView from '~/components/page-building/components/tab-content-view/TabContentView.vue';
import TemplateMaterialFilter from '~/components/page-building/template-gallery/TemplateMaterialFilter.vue';
import TemplateSizeFilter from '~/components/page-building/template-gallery/TemplateSizeFilter.vue';
import { scrollToId, slugify } from '~/utils/helpers';
import ImageType from '~/types/ImageType';

defineProps<{
    data: TemplateGalleryType;
}>();

const state = reactive({
    tabs: [] as TabContentViewTabType[],
    products: [] as TemplateGalleryProductType[],
    currentProduct: '' as string,
    currentMaterial: null as TemplateGalleryProductMaterialType | null,
    currentSize: null as TemplateGalleryProductMaterialSizeType | null,
});

const { tabs, products, currentProduct, currentMaterial, currentSize } =
    toRefs(state);

const selectProduct = () => {
    currentMaterial.value = null;
    currentSize.value = null;
    scrollToId('product-container');
};

const selectMaterial = (material: TemplateGalleryProductMaterialType) => {
    currentMaterial.value = material;
    currentSize.value = null;
};

const selectSize = (size: TemplateGalleryProductMaterialSizeType) => {
    currentSize.value = size;
};

const materialsForProduct = (prod: any) => {
    const materials: TemplateGalleryProductMaterialType[] = [];

    prod.templates?.forEach((template: any) => {
        if (
            !materials.some(
                (el) => el.name === (template.material ?? 'Default'),
            )
        ) {
            materials.push({
                name: template.material ?? 'Default',
                description: '',
                sizes: prod.templates
                    .filter((temp: any) => temp.material === template.material)
                    .map((temp: any) => {
                        let dimensions = [];
                        dimensions = [
                            {
                                dimension: 'Front',
                                subdimension1: 'Height',
                                measurement1: temp.height + ' in',
                                subdimension2: 'Width',
                                measurement2: temp.frontWidth + ' in',
                            } as TemplateGalleryProductMaterialSizeDimensionType,
                            {
                                dimension: 'Back',
                                subdimension1: 'Height',
                                measurement1: temp.height + ' in',
                                subdimension2: 'Width',
                                measurement2: temp.backWidth + ' in',
                            } as TemplateGalleryProductMaterialSizeDimensionType,
                        ];

                        if (temp.side1Width) {
                            dimensions.push({
                                dimension: 'Gussets',
                                subdimension1: 'Gusset 1',
                                measurement1: temp.side1Width + ' in',
                                subdimension2: 'Gusset 2',
                                measurement2: temp.side2Width + ' in',
                            } as TemplateGalleryProductMaterialSizeDimensionType);
                        }

                        return {
                            name: temp.size,
                            artworkTemplatePDF: temp.productId
                                ? '/products/' +
                                  temp.productId +
                                  '/download-template'
                                : null,
                            tolerancePDF: prod.shapeId
                                ? '/shapes/' + prod.shapeId + '/download-file/1'
                                : null,
                            dimensions: dimensions,
                        } as TemplateGalleryProductMaterialSizeType;
                    }),
            });
        }
    });
    return materials;
};

const { portalUrl } = useRuntimeConfig().public;
await useFetch(`${portalUrl}/api/artwork-template/tabs`).then((response) => {
    products.value = response.data.value.map((prod: any) => {
        return {
            name: prod.title,
            image: {
                title: prod.title,
                alt: prod.title,
                src: prod.imageUrl,
            } as ImageType,
            materials: materialsForProduct(prod),
            artworkGuide: prod.artworkGuide ? prod.artworkGuide : null,
        } as TemplateGalleryProductType;
    });
    tabs.value = products.value.map((product: TemplateGalleryProductType) => {
        return {
            image: product.image,
            label: product.name,
            slug: slugify(product.name),
        } as TabContentViewTabType;
    });
    currentProduct.value = tabs.value[0].slug;
});
</script>
